<script setup lang="ts">
   import dayjs from 'dayjs'

    const now = useNow()
    const newNow = ref("")
    watch(now, (val) => {
        newNow.value = dayjs(val).format('HH:mm:ss')
    })
    newNow.value = dayjs(now.value).format('YYYY-MM-DD HH:mm:ss')
</script>
<template>
        <div class="flex items-center space-x-2 text-slate-200">
             <VaIcon name="schedule" size="large"></VaIcon>   <h2 class=" p-2  border-slate-200 rounded-md text-slate-200">{{ newNow }}</h2>
  </div>
               
</template>